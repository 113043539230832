import React from "react"

import MenuLayout from "../components/menulayout"
import SEO from "../components/seo"

const Contact = () => (
  <MenuLayout customClasses="contact">
    <SEO title="Kontakt" />
    <div className="row">
      <div className="map"></div>
      <article className="col-xs-12">
        <h3>Adresse. Telefon. E-Mail.</h3>
        <p className="hero">
          Rufen Sie an, oder schreiben Sie.
          <br />
          Ich freue mich auf ein Gespräch und auf Ihre Ideen.
        </p>
        <p>
          <strong>Rivic Visuals.</strong>
          <br />
          Rettichgasse 7/4/6
          <br />
          1140 Wien, Österreich
          <br />
          <br />
          +43 660 575 46 45
          <br />
          <a href="mailto:luka@rivic.at">luka@rivic.at</a>
          <br />
          <br />
          Mo – Do: 09:00 – 18:00
          <br />
          Fr: 09:00 – 15:00
        </p>
      </article>
    </div>
  </MenuLayout>
)

export default Contact
